import React, { useState, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Link, useHistory } from 'react-router-dom'
import { useQuery } from 'react-query'
import { client } from '../utils/api-client'
import ReactMarkdown from '../components/react-markdown'
import Truncate from 'react-truncate'
import Markdown from '../components/markdown'
import { Modal, Button } from 'react-bootstrap'
import { useAuth } from '../context/auth'
import ErrorPage from './error/Default'
import Loading from '../elements/Loading'

import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'

import Avatar from '../components/elements/avatar'
import Breadcrumb from '../elements/Breadcrumb'

import Gallery from '../elements/Gallery'
import PreviewDirectory from '../components/directory/PreviewDirectory'
import Card from '../components/elements/card'
import Wishlist from '../components/directory/elements/wishlist'
import FormModal from '../components/chat/form_old'

import ScrollAnimation from 'react-animate-on-scroll'
import { Parser } from 'html-to-react'

export default (props) => {
  const { slug } = useParams()
  const history = useHistory()
  const [show, setShow] = useState(false)
  const { me } = useAuth()

  const { data, isLoading, isError, error } = useQuery(['projects', slug], () =>
    client(`projects?slug=${slug}`)
  )

  if (isLoading) {
    return <Loading {...props} />
  }

  if (isError) {
    console.log('PROJECTS ERROR', error)
    return <ErrorPage {...props} />
  }

  const page = data[0]
  const seo = page['SEO']

  const renderCategories = (page) => {
    return (
      <div className="categories">
        {page.categories.map((category) => (
          <Link
            to={`/collections/${category.slug}`}
            key={`category-${category.slug}`}
            className="badge badge-pill badge-transparent px-3 py-2 mb-4 mr-2"
          >
            {category.title}
          </Link>
        ))}
      </div>
    )
  }
  const htmlParser = new Parser()

  const renderProfileInfo = (profile) => {
    if (profile) {
      return (
        <div className="profile">
          <Avatar
            {...profile}
            subTitle={props.title}
            classNameTitle="h4 text-dark p-0 m-0"
          />
          <Truncate lines={5} className="d-block text-muted my-4 text-justify">
            {htmlParser.parse(profile.description)}
          </Truncate>
          {profile.address ? (
            <p className="text-primary">
              <i className="fa-map-marker-alt fa mr-1"></i>{' '}
              <a
                className="text-primary"
                rel="noopener noreferrer"
                target="_blank"
                href={`https://maps.google.com?q=${profile.address.street}+${profile.address.postalCode}+${profile.address.city}`}
              >{`${profile.address.street}, ${profile.address.postalCode} ${profile.address.city}`}</a>
            </p>
          ) : null}
          {profile.email ? (
            <p className="text-primary d-none">
              <i className="fa-paper-plane fa mr-1"></i>{' '}
              <a className="text-primary" href={`mailto:${profile.email}`}>
                {profile.email}
              </a>
            </p>
          ) : null}
          <div className="text-center">
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUp"
              animateOnce={true}
              delay={1 * 50}
              _style={{ display: 'inline-block' }}
            >
              {/* // CHAT TODO */}
              {/* <Button onClick = {()=>openChatWindow(profile)} className = "btn btn-primary text-light mt-3">Stuur een bericht</Button> */}
              <FormModal professional={profile} />
            </ScrollAnimation>
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUp"
              animateOnce={true}
              delay={2 * 50}
              style={{ display: 'inline-block' }}
            >
              <Link
                to={`/members/profile/${profile.slug}`}
                className="btn btn-text ml-md-2 mt-md-0 mt-2"
              >
                Bekijk profiel
              </Link>
            </ScrollAnimation>
          </div>
        </div>
      )
    }
  }

  // cache uitzetten
  const renderRelatedItems = (item) => (
    <PreviewDirectory
      endpoint={`projects/${item.id}/related`}
      displayPagination={false}
      _limit={3}
      renderWrapper={({ results }) => <>{results()}</>}
      renderItem={(item, index) => (
        <div
          className="col-md-4 col-12 mb-5"
          key={`project-related-items-card-${item._id || item.id}`}
        >
          <ScrollAnimation
            animateIn="animate__animated animate__fadeInUpSmall"
            animateOnce={true}
            delay={index * 50}
          >
            <Card {...item} />
          </ScrollAnimation>
        </div>
      )}
    />
  )

  // cache uitzetten
  const renderOtherItems = (profile, pageId) => {
    if (profile) {
      return (
        <PreviewDirectory
          endpoint={`projects/getOtherProjects/${pageId}/${profile.id}`}
          displayPagination={false}
          _limit={3}
          renderWrapper={({ results }) => <>{results()}</>}
          renderItem={(item, index) => (
            <div
              className="col-md-4 col-12 mb-5"
              key={`project-other-items-card-${item.id}`}
            >
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={index * 50}
              >
                <Card {...item} />
              </ScrollAnimation>
            </div>
          )}
        />
      )
    }
  }

  return (
    <div className={`page ${page.slug}`}>
      <Head pageTitle={page.title} seo={seo} />
      <ScrollRestoration />

      <section className="py-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={1 * 50}
                offset={-50}
              >
                {page.categories.length ? (
                  <Breadcrumb
                    pages={[
                      {
                        slug: '/collections',
                        title: 'Inspiratie',
                      },
                      {
                        slug: '/collections/' + page.categories[0].slug,
                        title: page.categories[0].title,
                      },
                      // page
                    ]}
                  />
                ) : (
                  <Breadcrumb
                    pages={[
                      {
                        slug: '/collections',
                        title: 'Inspiratie',
                      },
                      // page
                    ]}
                  />
                )}
              </ScrollAnimation>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-md-12">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={2 * 50}
              >
                <h1>
                  {page.title}
                  <Wishlist
                    handleClick={() => !me && setShow(!show)}
                    {...page}
                    render={(props) => (
                      <a
                        className={`btn btn-outline-primary float-right btn-large rounded-circle m-1 px-0 ${
                          props.isActive() ? 'active' : ''
                        }`}
                        style={{ width: '40px', height: '40px' }}
                        href="#"
                        onClick={props.handleClick}
                      >
                        <i
                          className="fa fa-heart"
                          style={{ width: '40px', height: '40px' }}
                        ></i>
                      </a>
                    )}
                  />
                </h1>
              </ScrollAnimation>
            </div>
          </div>

          <div className="row">
            <div className="col-12 px-4">
              <ScrollAnimation
                animateIn="animate__animated animate__kenBurns"
                animateOnce={true}
                delay={3 * 50}
              >
                <Gallery
                  title={page.title}
                  images={page.images}
                  numberOfThumbs={6}
                />
              </ScrollAnimation>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-4">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUp"
                animateOnce={true}
                delay={6 * 50}
              >
                {renderCategories(page)}
              </ScrollAnimation>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-12 col-md-8">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUp"
                animateOnce={true}
                delay={5 * 50}
              >
                <h2 className="h4">Over dit project</h2>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={5 * 50}
              >
                <div className="text-muted _two-column text-justify">
                  {htmlParser.parse(page.description)}
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-12 col-md-4">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={5 * 50}
              >
                <div className="card ml-lg-4 shadow bg-white p-4 rounded-0 border-0">
                  {renderProfileInfo(page.professionals[0])}
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5 py-lg-7 bg-gray-100 d-none">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={0 * 50}
              >
                {renderProfileInfo(page.professionals[0])}
              </ScrollAnimation>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-gray-100">
        <div className="container py-5 py-lg-7">
          <div className="row mb-4 mx-1">
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUp"
              animateOnce={true}
              delay={0 * 50}
            >
              <h2 className="h3">Andere projecten van deze professional</h2>
            </ScrollAnimation>
          </div>
          <div className="row justify-content-center">
            {renderOtherItems(page.professionals[0], page.id)}
          </div>

          <div className="row mt-5 mb-4 mx-1">
            <ScrollAnimation
              animateIn="animate__animated animate__fadeInUp"
              animateOnce={true}
              delay={0 * 50}
            >
              <h2 className="h3">Gerelateerde projecten</h2>
            </ScrollAnimation>
          </div>
          <div className="row justify-content-center">
            {renderRelatedItems(page)}
          </div>
        </div>
      </section>

      {/* Register an account Modal */}
      <Modal show={show} onHide={() => setShow(false)} centered>
        <Modal.Body>
          <button
            className="close"
            type="button"
            onClick={() => setShow(false)}
            aria-label="Close"
          >
            <span aria-hidden="true">&times; </span>
          </button>
          <Modal.Title>Maak een account aan.</Modal.Title>
          <p>Met een account kun je eenvoudig een wensenlijstje aanmaken.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={() => setShow(false)}>
            Nee nog niet
          </Button>
          <Button variant="primary" onClick={() => history.push('/register')}>
            Registreren
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}
