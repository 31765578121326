import React from 'react'
import { getImageURL } from '../../../core/helpers/Image'
import { Link } from 'react-router-dom'
import { IKImage } from 'imagekitio-react'

export default ({ Date, Title, Text, slug, cover }) => {
  return (
    <div className="card">
      <IKImage
        className="img-fluid card-img-top"
        path={getImageURL(cover).split('/').pop()}
        srcSet={`
        https://ik.imagekit.io/ia4gqyhdt/tr:w-350,h-300/${getImageURL(cover)
          .split('/')
          .pop()} 460w,

        https://ik.imagekit.io/ia4gqyhdt/tr:w-500,h-250/${getImageURL(cover)
          .split('/')
          .pop()} 760w,

        https://ik.imagekit.io/ia4gqyhdt/tr:w-400,h-350/${getImageURL(cover)
          .split('/')
          .pop()} 925w,
        https://ik.imagekit.io/ia4gqyhdt/tr:w-400,h-350/${getImageURL(cover)
          .split('/')
          .pop()} 1450w,
      `}
      />
      <div className="card-body">
        <p className="card-date">{Date}</p>
        {/* .card-date */}
        <p className="card-title">{Title}</p>
        {/* .card-title */}
        <p className="card-text d-md-none">{Text}</p>
        {/* .card-title */}
        <Link className="btn btn-link stretched-link" to={`/blog/${slug}`}>
          Lees dit artikel
        </Link>
        {/* .btn */}
      </div>
      {/* .card-body */}
    </div>
    // {/* .card */}
  )
}
