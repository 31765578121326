import React from 'react'
import { Helmet } from 'react-helmet'

export default ({ pageTitle = '', seo = [] }) => {
  const meta = seo ? seo.find((o) => o.__component === 'seo.meta') || {} : {}
  const openGraph = seo
    ? seo.find((o) => o.__component === 'seo.open-graph') || {}
    : {}

  return (
    <Helmet>
      <title>{pageTitle || 'HQUE - Redefining Spaces'}</title>

      {/* Meta */}
      <meta charSet="utf-8" />
      <meta
        name="description"
        content={
          meta
            ? meta.meta_description
            : 'HQUE is hét online inspiratieplatform voor workspaces. Zowel interieur als exterieur. Ontdek een wereld van high-end designers, architecten en specialisten.'
        }
      />

      {/* Social */}
      <meta property="og:type" content="website" />
      <meta property="og:title" content={openGraph ? openGraph.og_title : ''} />
      <meta
        property="og:description"
        content={openGraph ? openGraph.og_description : ''}
      />
      <meta
        property="og:image"
        content={openGraph && openGraph.og_image ? openGraph.og_image.url : ''}
      />

      <meta
        property="og:url"
        content={openGraph ? openGraph.og_url : 'https://www.enorm.com/'}
      />

      {/* Fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
        rel="stylesheet"
      ></link>

      {/* Styles */}
      <link
        rel="stylesheet"
        href="https://pro.fontawesome.com/releases/v5.15.1/css/all.css"
        integrity="sha384-9ZfPnbegQSumzaE7mks2IYgHoayLtuto3AS6ieArECeaR8nCfliJVuLh/GaQ1gyM"
        crossorigin="anonymous"
      />

      <link
        rel="stylesheet"
        href="https://use.fontawesome.com/releases/v5.8.1/css/all.css"
        integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf"
        crossorigin="anonymous"
      />
    </Helmet>
  )
}
