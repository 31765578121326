import React, { useContext } from 'react'
import { Redirect, useParams } from 'react-router-dom'
import useAxios from 'axios-hooks'
import Markdown from '../components/markdown'

import ErrorPage from './error/Default'
import Loading from '../elements/Loading'

import Head from '../core/Head'
import ScrollRestoration from '../core/ScrollRestoration'
import Placeholder from '../seed/icons/placeholder-icon.svg'

import Avatar from '../components/elements/avatar'
import Breadcrumb from '../elements/Breadcrumb'
import ProfileCover from '../elements/ProfileCover'

import PreviewDirectory from '../components/directory/PreviewDirectory'
import Card from '../components/elements/card'

import { Button, Image } from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll'
import FormModal from '../components/chat/form_old'
import { useQuery } from 'react-query'
import { client } from '../utils'

export default (props) => {
  const { slug } = useParams()
  let params

  if (typeof window !== 'undefined') {
    params = Object.fromEntries(
      new URLSearchParams(window.location.search).entries()
    )
  }

  const { data, isLoading, isError, error } = useQuery(['profiles', slug], () =>
    client(`professionals?slug=${slug?.replaceAll('.', '')}`)
  )

  if (isLoading && Array.isArray(data) === false) {
    return <Loading {...props} />
  }

  if (isError && Array.isArray(data) === false) {
    console.log('ERROR', error)
    return <Redirect to="/pagina-niet-gevonden" />
  }

  console.log('isLoading ', isLoading)
  console.log('isError ', isError)
  console.log('data ', data)
  console.log('error ', error)

  const renderProfileInfo = (profile) => {
    if (profile) {
      return (
        <div className="profile">
          <ScrollAnimation
            animateIn="animate__animated animate__fadeInUpSmall"
            animateOnce={true}
            delay={2 * 50}
          >
            <Avatar
              {...profile}
              size="avatar-lg"
              subLabel={true}
              classNameTitle="h1 text-dark"
              displayFollowProfessional={true}
            />
          </ScrollAnimation>

          <div className="row">
            <div className="col-12 col-md-8">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={3 * 50}
              >
                <Markdown
                  className="text-muted my-4 text-justify"
                  content={profile.description}
                />
              </ScrollAnimation>
            </div>
            <div className="col-12 col-md-4">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={4 * 50}
              >
                <div className="card ml-lg-4 shadow bg-white p-4">
                  <h2>Contact</h2>
                  <div className="row">
                    {profile.address && (
                      <div className="col-12">
                        <p className="text-primary">
                          <i className="fa-map-marker-alt fa mr-1"></i>{' '}
                          <a
                            className="text-dark"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://maps.google.com?q=${profile.address.street}+${profile.address.postalCode}+${profile.address.city}`}
                          >{`${profile.address.street}, ${profile.address.postalCode} ${profile.address.city}`}</a>
                        </p>
                      </div>
                    )}
                    {profile.email && (
                      <div className="col-12 d-none">
                        <p className="text-primary">
                          <i className="fa-paper-plane fa mr-1"></i>{' '}
                          <a
                            className="text-dark"
                            href={`mailto:${profile.email}`}
                          >
                            {profile.email}
                          </a>
                        </p>
                      </div>
                    )}
                  </div>
                  <ScrollAnimation
                    animateIn="animate__animated animate__fadeInUp"
                    animateOnce={true}
                    delay={5 * 50}
                  >
                    {/* // CHAT TODO */}
                    {/* <Button onClick = {()=>openChatWindow(profile)} className = "btn btn-primary text-light mt-3">Stuur een bericht</Button> */}
                    <FormModal professional={profile} />
                  </ScrollAnimation>
                </div>
              </ScrollAnimation>
            </div>
          </div>
        </div>
      )
    }
  }

  const renderSubItems = (item) => (
    <PreviewDirectory
      endpoint="projects"
      displayPagination={true}
      _limit={24}
      query={{
        professionals_in: item.id,
      }}
      renderWrapper={({ results, renderPagination }) => (
        <div className="row" key={`profile-items-${item.id}`}>
          {results()}
        </div>
      )}
      renderItem={(item, index) => (
        <div
          className="col-md-4 col-12 mb-5"
          key={`profile-items-card-${item.id}`}
        >
          <ScrollAnimation
            animateIn="animate__animated animate__fadeInUpSmall"
            animateOnce={true}
            delay={(index % 3) * 50}
          >
            <Card {...item} />
          </ScrollAnimation>
        </div>
      )}
    />
  )

  return Array.isArray(data) ? (
    <div className={`page ${data[0]?.slug}`}>
      <Head pageTitle={data[0]?.name} seo={data[0]['SEO']} />
      <ScrollRestoration />
      <ProfileCover profile={data[0]} />

      <section className="py-4">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <ScrollAnimation
                animateIn="animate__animated animate__fadeInUpSmall"
                animateOnce={true}
                delay={1 * 50}
                offset={-50}
              >
                <Breadcrumb
                  pages={[
                    {
                      slug: '/members',
                      title: 'Professionals',
                    },
                    {
                      slug: '/members/profile/' + data[0].slug,
                      title: data[0].name,
                    },
                  ]}
                />
              </ScrollAnimation>
            </div>
          </div>

          <div className="row mb-4 d-flex justify-content-center">
            <div className="col-12">{renderProfileInfo(data[0])}</div>
          </div>

          <ScrollAnimation
            animateIn="animate__animated animate__fadeInUp"
            animateOnce={true}
            delay={6 * 50}
          >
            <div className="row my-4 pt-4 border-top">
              <div className="col-12">
                <ScrollAnimation
                  animateIn="animate__animated animate__fadeInUp"
                  animateOnce={true}
                  delay={6 * 50}
                >
                  <h2>Portfolio</h2>
                </ScrollAnimation>
              </div>
            </div>
          </ScrollAnimation>

          <div className="row my-4">
            <div className="col-12">{renderSubItems(data[0])}</div>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <Redirect to="/pagina-niet-gevonden" />
  )
}
